<template>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <form @submit.prevent="onSubmit">
        <section class="section">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="">
              <h4 class="title">Tambah Fee Man Power</h4>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/data-payroll">Payroll </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Tambah
                  </li>
                </ol>
              </nav>
            </div>
            <div>
              <button :disabled="isSubmit" class="btn btn-save mr-3">
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                {{ enableSync ? "Simpan & Sync" : "Simpan" }}
              </button>
              <!-- <button type="button" class="btn btn-blue">Simpan & Sync</button> -->
            </div>
          </div>

          <div class="card-table info-detail">
            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div class="row">
                  <div class="col-12">
                    <div class="title">Tambah Fee Man Power</div>
                    <div class="sub-title">
                      Tambah informasi Fee Man Power yang diperlukan
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Departemen :</label>
                      <Select2
                        v-model="formData.id_divisi"
                        :options="optionDepartement"
                        :settings="{ allowClear: true }"
                        placeholder="Pilih Departemen"
                        @change="formChange('id_divisi', $event)"
                        @select="formChange('id_divisi', $event)"
                        :class="{
                          'is-invalid': formError && formError.id_divisi,
                        }"
                      />
                    </div>
                  </div>

                  <div class="col-6">
                    <div class="form-group">
                      <label>Bulan :</label>
                      <Datepicker
                        v-model="formData.bulan"
                        :format="formatMonth"
                        :autoApply="true"
                        placeholder="Pilih Bulan"
                        monthPicker
                      />
                    </div>
                  </div>

                  <div class="col-6">
                    <div class="form-group">
                      <label>Tanggal Bayar :</label>
                      <Datepicker
                        :autoApply="true"
                        :closeOnScroll="true"
                        v-model="formData.tanggal_bayar"
                        placeholder="Tanggal Bayar"
                        :format="format"
                        :enableTimePicker="false"
                        locale="id-ID"
                        selectText="Pilih"
                        cancelText="Batal"
                        :class="{
                          'is-invalid': formError && formError.tanggal_bayar,
                        }"
                        @update:modelValue="changeDate($event, 'tanggal_bayar')"
                      ></Datepicker>
                    </div>
                  </div>

                  <div class="col-6">
                    <div class="form-group">
                      <label>Akun Kas & Bank :</label>
                      <Select2
                        v-model="formData.akun_perkiraan"
                        :options="optionAkun"
                        :settings="{ templateResult: formatState }"
                        placeholder="Pilih Akun Kas & Bank"
                        @change="formChange('akun_perkiraan')"
                        @select="formChange('akun_perkiraan')"
                        :class="{
                          'is-invalid': formError && formError.akun_perkiraan,
                        }"
                      />
                    </div>
                  </div>

                  <div class="col-6">
                    <div class="form-group">
                      <label>Tipe Bayar :</label>
                      <Select2
                        v-model="formData.tipe_bayar"
                        :options="optionPaymentType"
                        placeholder="Pilih Tipe Bayar"
                        @change="formChange('tipe_bayar')"
                        @select="formChange('tipe_bayar')"
                        :class="{
                          'is-invalid': formError && formError.tipe_bayar,
                        }"
                      />
                    </div>
                  </div>

                  <div class="col-6">
                    <div class="form-group">
                      <label>Buat Fee Man Power berdasarkan?</label>
                      <div>
                        <div
                          v-for="(value, index) in optionGenerate"
                          class="custom-control custom-radio custom-control-inline" :key="index">
                          <input
                            type="radio"
                            :id="`createdBy${value.id}`"
                            v-model="formData.created_by"
                            @change="formChange('created_by', value.id)"
                            :value="value.id"
                            class="custom-control-input"
                          />
                          <label class="custom-control-label" :for="`createdBy${value.id}`"
                            >{{ value.text }}</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-6" v-if="formData.created_by == 'project'">
                    <div class="form-group">
                      <label>Pilih Project :</label>
                      <Select2
                        v-model="formData.kode_project"
                        :options="optionProject"
                        placeholder="Pilih Project"
                        @change="formChange('kode_project')"
                        @select="formChange('kode_project')"
                        :class="{
                          'is-invalid': formError && formError.kode_project,
                        }"
                      />
                    </div>
                  </div>

                  <div class="col-6">
                    <div class="form-group">
                      <label class="">Keterangan :</label>
                      <textarea
                        type="text"
                        class="form-control"
                        placeholder="Masukkan Keterangan"
                        v-model="formData.keterangan"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label>Item Potongan PPh 21</label>
                      <Select2
                        v-model="formData.item_pph"
                        :options="optionPotongan"
                        placeholder="Pilih Item"
                        @change="formChange('item_pph')"
                        @select="formChange('item_pph')"
                        :class="{
                          'is-invalid': formError && formError.item_pph,
                        }"
                      />
                    </div>
                  </div>
                </div>

                <div class="row align-items-center mt-5">
                  <div class="col-6">
                    <div class="title">Tambah Man Power Payroll</div>
                    <div class="sub-title">
                      Rincian dari tambah man power payroll yang diperlukan
                    </div>
                  </div>

                  <div class="col-6 text-right">
                    <button
                      type="button"
                      v-if="(formData.kode_project && this.formData.created_by == 'project') || (this.formData.created_by == 'nama_karyawan')"
                      :disabled="onGetData"
                      class="btn btn-add"
                      @click="getManPowerList"
                    >
                      <span
                        class="fas fa-spinner fa-spin"
                        v-if="onGetData"
                      ></span>
                      Ambil Data
                    </button>
                  </div>
                </div>
                <hr />
                <div class="table-responsive mt-4">
                  <table
                    class="table table-bordered table-striped table-hover"
                    id="tablePayrollBonus"
                  >
                    <thead>
                      <tr>
                        <th style="width: 5%">
                          <div class="form-check pl-0 text-center" v-if="listKaryawan.length > 0">
                            <label
                              class="form-check-label"
                              :for="'check-opexAll'"
                            >
                              <input
                                v-model="allEmployee"
                                type="checkbox"
                                value="true"
                                @change="setAllEmployee"
                                :id="'check-opexAll'"
                              />
                            </label>
                          </div>
                        </th>
                        <th style="width: 20%">ID Karyawan</th>
                        <th style="width: 20%">Nama</th>
                        <th style="width: 15%">Subtotal</th>
                        <th style="width: 15%">Kasbon</th>
                        <th style="width: 15%">PPh 21</th>
                        <th style="width: 20%">Total Gaji</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(value, index) in listKaryawan" :key="index">
                        <td>
                          <div
                            class="form-check pl-0 text-center"
                          >
                            <label
                              class="form-check-label"
                              :for="'check-group' + index"
                            >
                              <input
                                v-model="selectedEmployee"
                                type="checkbox"
                                :value="value.id_karyawan"
                                :id="'check-group' + index"
                              />
                            </label>
                          </div>
                        </td>
                        <td>{{ value.id_karyawan }}</td>
                        <td>{{ value.nama_karyawan }}</td>
                        <!-- <td class="text-center">{{ value.frq }}</td> -->
                        <td>
                          <div class="d-flex justify-content-between">
                            <div>Rp</div>
                            <div>{{ formatMoney(value.nilai_pemasukan) }}</div>
                          </div>
                        </td>
                        <td>
                          <div class="d-flex justify-content-between">
                            <div>Rp</div>
                            <div>{{ formatMoney(value.total_kasbon) }}</div>
                          </div>
                        </td>
                        <td>
                          <div class="input-group">
                            <input type="text" v-model.lazy="listKaryawan[index].pph" @keyup="changePph(index)" v-number="configMoney" style="background: white" class="form-control text-right">
                            <div class="input-group-prepend">
                              <span class="input-group-text" style="background-color: white;">%</span>
                            </div>
                          </div>
                        </td>
                        <!-- <td>{{ value.nama_level }}</td> -->
                        <td>
                          <div class="d-flex justify-content-between">
                            <div>Rp</div>
                            <div>{{ formatMoney(value.nilai_pemasukan - value.nilai_potongan - value.nilai_pph) }}</div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../../components/Sidebar.vue";
import NavbarDashboard from "../../../../components/Navbar.vue";
import Select2 from "vue3-select2-component";
import Datepicker from "vue3-date-time-picker";
import { ref } from "vue";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import $ from "jquery";

import {
  get_PayrollPraSave,
  get_PayrollPreviousItem,
  get_PayrollProjectList,
  get_PayrollProjectManPower,
  post_PayrollSave,
} from "../../../../actions/payroll";
import { checkRules, cksClient, showAlert, checkApp } from "../../../../helper";
import { VMoney } from "v-money";
import moment from "moment";
import "moment/locale/id";
import { get_AkunList } from "../../../../actions/akun_perkiraan";
import { get_ListDivisi } from "../../../../actions/master";
// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
    Datepicker,
    // TableLite
  },

  directives: {
    number: VMoney,
  },

  data() {
    return {
      enableSync: checkApp("rawuh") ? true : false,
      optionPaymentType: [
        { id: "bulanan", text: "Bulanan" },
        { id: "non-bulanan", text: "Non-bulanan" },
      ],
      optionGenerate: [
        {
          id: "nama_karyawan",
          text: "Nama Karyawan & Freelance",
        },
        {
          id: "project",
          text: "Project",
        },
      ],
      optionProject: [],
      optionDepartement: [],
      optionAkun: [],
      optionPotongan: [],
      id_company: cksClient().get("_account").id_company,
      configMoney: {
        decimal: ".",
        thousands: "",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
      //formData
      isSubmit: false,
      formData: {
        id: "",
        tipe_bayar: "",
        id_company: cksClient().get("_account").id_company,
        id_divisi: "",
        kategori: "man power",
        bulan: "bulanan",
        tanggal_bayar: "",
        keterangan: "",
        akun_perkiraan: "",
        status_pembayaran: "belum",
        kode_project: "",
        created_by: "nama_karyawan",
        item_pph: '',
      },
      rules: {
        tipe_bayar: {
          required: true,
        },
        kode_project: {
          required: false,
        },
        kategori: {
          required: true,
        },
        bulan: {
          required: true,
          isArray: true,
        },
        tanggal_bayar: {
          required: true,
        },
        akun_perkiraan: {
          required: true,
        },
        item_pph: {
          required: true,
        }
      },
      pemasukan: [],
      potongan: [],

      subsidi: [],
      formError: [],
      listKaryawan: [],
      listPayrollItem: {
        pemasukan: [],
        potongan: [],
        subsidi: [],
      },
      dataPayroll: "",
      //addDetail
      onGetData: false,
      rulesDetail: {
        id_divisi: {
          required: true,
        },
        id_jabatan: {
          required: true,
        },
        id_karyawan: {
          required: true,
        },
      },
      formErrorDetail: [],
      formDetail: {
        id_divisi: "",
        nama_divisi: "",
        id_jabatan: "",
        nama_jabatan: "",
        id_karyawan: "",
        nama_karyawan: "",
        pemasukan: {},
        potongan: {},
        subsidi: {},
        total: 0,
        gaji_diterima: 0,
        nilai_pemasukan: 0,
        nilai_potongan: 0,
        nilai_subsidi: 0,
        masa_jabatan: "",
        key: "",
      },
      allEmployee: false,
      selectedEmployee: [],
    };
  },

  setup() {
    const date = ref(new Date());

    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const day = date.getDate();
      const monthText = month[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${monthText} ${year}`;
    };
    return {
      date,
      format,
    };
  },

  mounted() {
    this.setTable();
    // this.tooltip();
  },

  created() {
    this.getPrePayroll();
    this.getDivisi();
    // this.getPosition();
    // this.getEmployee();
    this.getProject();
    this.getAkun();
  },

  beforeUnmount() {
    if (this.dataPayroll) {
      this.dataPayroll.destroy();
      this.dataPayroll = "";
    }
  },

  methods: {
    getPrePayroll() {
      get_PayrollPraSave(
        {
          tipe: "gaji",
          id_company: this.id_company,
        },
        (res) => {
          var { potongan } = res.item;
          this.optionPotongan = [];
          for (const key in potongan) {
            if (Object.hasOwnProperty.call(potongan, key)) {
              const element = potongan[key];
              if (element.id_tipe == 12 || element.id_tipe == 11) {
                this.optionPotongan.push({
                id: element.id,
                text: element.nama
              })
              }
            }
          }
          // console.log(item);
          // this.listPayrollItem = item;
        }
      );
    },
    setAllEmployee(){
      if (!this.allEmployee) {
        this.selectedEmployee = [];
      }
      for (const key in this.listKaryawan) {
        if (Object.hasOwnProperty.call(this.listKaryawan, key)) {
          this.listKaryawan[key].checked = this.allEmployee ? 1 : 0;
          if (this.allEmployee){
            this.selectedEmployee.push(this.listKaryawan[key].id_karyawan)
          }
        }
      }

    },
    setTable() {
      if (this.dataPayroll) {
        this.dataPayroll.destroy();
      }
      setTimeout(() => {
        this.dataPayroll = $("#tablePayrollBonus").DataTable({
          info: false,
          order: [],
        });
      }, 500);
    },
    changePph(index) {
      var pph = parseFloat(this.listKaryawan[index].pph);
      var pemasukan = this.listKaryawan[index].nilai_pemasukan;
      var potongan = Math.ceil((pemasukan * pph) /100)
      this.listKaryawan[index].nilai_pph = potongan
    },
    //MASTER
    getProject() {
      var id_divisi = this.formData.id_divisi ? this.formData.id_divisi : "";
      this.optionProject = [];
      get_PayrollProjectList(
        {
          id_company: this.id_company,
          id_divisi: id_divisi,
        },
        (res) => {
          this.optionProject = res.list;
        }
      );
    },

    getDivisi() {
      get_ListDivisi({ id_company: this.id_company }, (res) => {
        const { list } = res;
        this.optionDepartement = [];
        for (const key in list) {
          if (Object.hasOwnProperty.call(list, key)) {
            const element = list[key];
            this.optionDepartement.push(element);
          }
        }
      });
    },

    getManPowerList() {
      var canGetData = true;
      if (this.formData.created_by == 'nama_karyawan') {
        if (!this.formData.bulan || (this.formData.bulan && !this.formData.bulan.year)) {
          canGetData = false;
          showAlert(this.$swal, {
            title: 'PERHATIAN!',
            msg: 'Silakan pilih bulan terlebih dahulu',
            showCancelButton: true,
            showConfirmButton: false,
          })
        }
      } else if(!this.formData.kode_project) {
        canGetData = false;
      }

      if(canGetData) {
        this.onGetData = true;
        this.listKaryawan = [];
        if (this.dataPayroll) {
          this.dataPayroll.destroy();
          this.dataPayroll = "";
        }

        var params = {
          id_company: this.id_company,
          kode_project: this.formData.kode_project,   
          created_by: this.formData.created_by,
          id_divisi: this.formData.id_divisi,
          bulan: this.formData.bulan ?  moment( this.formData.bulan.year + "-" + (this.formData.bulan.month + 1) + "-01").format("YYYY-MM") : ''
        }
        
        get_PayrollProjectManPower(params,
          (res) => {
            this.onGetData = false;
            if (!res.id_item) {
              showAlert(this.$swal, {
                title: "PERHATIAN!",
                msg: "Item penggajian untuk Fee Man Power belum anda atur, silakan atur terlebih dahulu di pengaturan item penggajian",
                showCancelButton: true,
                showConfirmButton: false,
                onCancel: () => {
                  this.$router.push({
                    name: "PayrollItems",
                  });
                },
                onDismiss: () => {
                  this.$router.push({
                    name: "PayrollItems",
                  });
                },
              });
            } else if (!res.item_kasbon) {
              showAlert(this.$swal, {
                title: "PERHATIAN!",
                msg: "Item penggajian untuk Kasbon belum anda atur, silakan atur terlebih dahulu di pengaturan item penggajian",
                showCancelButton: true,
                showConfirmButton: false,
                onCancel: () => {
                  this.$router.push({
                    name: "PayrollItems",
                  });
                },
                onDismiss: () => {
                  this.$router.push({
                    name: "PayrollItems",
                  });
                },
              });
            } else {
              var id_item = res.id_item;
              this.listKaryawan = [];
              for (const key in res.list) {
                if (Object.hasOwnProperty.call(res.list, key)) {
                  var element = res.list[key];
                  var nilai_pemasukan = element.total;
                  element.pemasukan = {};
                  element.potongan = {};
                  element.subsidi = {};
                  element.potongan[res.item_kasbon] = element.total_kasbon ?? 0;
                  element.pemasukan[id_item] = nilai_pemasukan;
                  element.nilai_pemasukan = nilai_pemasukan;
                  element.pph = 0;
                  element.nilai_pph = 0;
                  element.nilai_potongan = element.total_kasbon ?? 0;
                  delete element.fee;
                  this.listKaryawan.push(element);
                }
              }
              this.setTable();
            }
          },
          () => {
            this.onGetData = false;
          }
        );
      }
    },

    getAkun() {
      get_AkunList(
        {
          id_company: this.id_company,
          tipe: 1,
        },
        (res) => {
          var { list } = res;
          var data = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              data.push({
                id: element.id,
                text: `${element.nama}`,
                no: element.id,
                type: element.tipe,
                sub_level: parseInt(element.sub_level),
                disabled:
                  parseInt(element.max_subLevel) === parseInt(element.sub_level)
                    ? false
                    : true,
              });
            }
          }
          this.optionAkun = data;
        }
      );
    },

    formatMonth(date) {
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const monthText = month[date.month];
      const year = date.year;

      return `${monthText} ${year}`;
    },

    formatMoney(a) {
      if (!a) {
        return 0;
      }
      return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    changeDate(data, key) {
      this.formData[key] = moment(data).format("YYYY-MM-DD");
      this.formChange(key);
    },

    changeMonth(data, key) {
      this.formData[key].length = data && data.year ? 2 : 0;
      this.formChange(key);
    },

    async formChange(key, val = "", tipe = "") {
      var check;
      if (tipe == "item") {
        if (this.rulesDetail[key]) {
          this.rulesDetail[key].changed = true;
        }
        if (key == "id_karyawan") {
          this.formDetail.id_divisi = val.id_divisi;
          this.formDetail.nama_jabatan = val.nama_jabatan;
          this.formDetail.nama_karyawan = val.text;
          this.formDetail.nama_divisi = val.nama_divisi;
          this.formDetail.id_jabatan = val.id_jabatan;
          this.formDetail.masa_jabatan = val.masa_jabatan;
        }
        check = await checkRules(this.rulesDetail, this.formDetail);
        this.formErrorDetail = check.error;
      } else {
        if (key == "id_divisi") {
          this.getProject();
        }

        if (key == "created_by") {
          this.listKaryawan = [];
          this.selectedEmployee = []
          this.allEmployee = false;
          this.setTable();
          if (val == "nama_karyawan") {
            this.formData.kode_project = ''
            this.rules.kode_project.required = false;
          } else {
            this.rules.kode_project.required = true;
          }
        }

        if (this.rules[key]) {
          this.rules[key].changed = true;
        }

        check = await checkRules(this.rules, this.formData);
        this.formError = check.error;
      }
    },

    searchObjectBarang(value) {
      return this.listKaryawan.find((element) => element.id_karyawan == value);
    },

    resultEmployee(event) {
      if (!event.id) {
        return event;
      }
      var check = this.searchObjectBarang(event.id);
      var divisi_check = false;
      var jabatan_check = false;
      if (this.formDetail.id_jabatan && this.formDetail.id_divisi) {
        jabatan_check =
          event.id_jabatan === parseInt(this.formDetail.id_jabatan)
            ? true
            : false;
        divisi_check =
          event.id_divisi === parseInt(this.formDetail.id_divisi)
            ? true
            : false;
        if (!check && divisi_check && jabatan_check) {
          return event.text;
        } else {
          return null;
        }
      } else if (this.formDetail.id_jabatan || this.formDetail.id_divisi) {
        jabatan_check =
          event.id_jabatan === parseInt(this.formDetail.id_jabatan)
            ? true
            : false;
        divisi_check =
          event.id_divisi === parseInt(this.formDetail.id_divisi)
            ? true
            : false;
        if (!check && (divisi_check || jabatan_check)) {
          return event.text;
        } else {
          return null;
        }
      } else {
        if (!check) {
          return event.text;
        } else {
          return null;
        }
      }
    },

    formatState(state) {
      if (!state.id) {
        return state.text;
      }
      var padding =
        state.sub_level == 2 ? "pl-3" : state.sub_level == 1 ? "pl-2" : "";
      var $state = $(
        '<div class="' +
          padding +
          '">' +
          state.text +
          '<div class="noEstimated" style="font-size: 12px;color: #898989; display:flex; justify-content: space-between;">' +
          state.no +
          "<div>" +
          state.type +
          "</div></div></div>"
      );
      return $state;
    },

    //employee
    insertEmployee() {
      this.formDetail.id_karyawan = "";
      this.formDetail.key = "";
      this.formDetail.id_divisi = "";
      this.formDetail.id_jabatan = "";
      this.formDetail.pemasukan = {};
      this.formDetail.potongan = {};
      this.formDetail.subsidi = {};
      $("#modalAddEmployee").modal("show");
    },

    async saveItem() {
      for (const key in this.rulesDetail) {
        if (Object.hasOwnProperty.call(this.rulesDetail, key)) {
          this.rulesDetail[key].changed = true;
        }
      }
      var check = await checkRules(this.rulesDetail, this.formDetail);
      this.formErrorDetail = check.error;
      var data = await this.countAllItem(this.formDetail);
      if (check.success && data.total > 0) {
        this.dataPayroll.destroy();
        if (this.formDetail.key !== "") {
          this.listKaryawan.splice(this.formDetail.key, 1);
        }
        var dataItem = { ...data };
        this.listKaryawan.push(dataItem);
        $("#modalAddEmployee").modal("hide");
        setTimeout(() => {
          this.dataPayroll = $("#tablePayrollBonus").DataTable({
            info: false,
          });
        }, 500);
      }
    },

    showDetail(value, index) {
      this.formDetail = { ...value };
      this.formDetail.key = index;
      $("#modalAddEmployee").modal("show");
    },

    countAllItem(data) {
      var pemasukan =
        Object.entries(data.pemasukan).length > 0 ? data.pemasukan : {};
      var potongan =
        Object.entries(data.potongan).length > 0 ? data.potongan : {};
      var subsidi = Object.entries(data.subsidi).length > 0 ? data.subsidi : {};

      var nilai_pemasukan = 0;
      var nilai_potongan = 0;
      var nilai_subsidi = 0;
      for (const key in pemasukan) {
        if (Object.hasOwnProperty.call(pemasukan, key)) {
          nilai_pemasukan += pemasukan[key]
            ? parseInt(pemasukan[key].toString().split(".").join(""))
            : 0;
        }
      }
      for (const key in potongan) {
        if (Object.hasOwnProperty.call(potongan, key)) {
          nilai_potongan += potongan[key]
            ? parseInt(potongan[key].toString().split(".").join(""))
            : 0;
        }
      }

      for (const key in subsidi) {
        if (Object.hasOwnProperty.call(subsidi, key)) {
          nilai_subsidi += subsidi[key]
            ? parseInt(subsidi[key].toString().split(".").join(""))
            : 0;
        }
      }
      var total = nilai_pemasukan + nilai_subsidi - nilai_potongan;
      var diterima = nilai_pemasukan - nilai_potongan;
      data.nilai_pemasukan = nilai_pemasukan;
      data.nilai_potongan = nilai_potongan;
      data.nilai_subsidi = nilai_subsidi;
      data.total = total;
      data.gaji_diterima = diterima;
      return data;
    },

    getPreviousData(tipe = "") {
      this.onGetData = true;
      get_PayrollPreviousItem(
        { tipe: tipe, id_karyawan: this.formDetail.id_karyawan },
        (res) => {
          var { pemasukan, potongan, subsidi } = res.item;
          this.onGetData = false;
          for (const key in pemasukan) {
            if (Object.hasOwnProperty.call(pemasukan, key)) {
              const element = pemasukan[key];
              if (
                Object.hasOwnProperty.call(
                  this.formDetail.pemasukan,
                  element.id
                )
              ) {
                this.formDetail.pemasukan[element.id] = this.formatMoney(
                  element.nilai
                );
              }
            }
          }

          for (const key in potongan) {
            if (Object.hasOwnProperty.call(potongan, key)) {
              const element = potongan[key];
              if (
                Object.hasOwnProperty.call(this.formDetail.potongan, element.id)
              ) {
                this.formDetail.potongan[element.id] = this.formatMoney(
                  element.nilai
                );
              }
            }
          }

          for (const key in subsidi) {
            if (Object.hasOwnProperty.call(subsidi, key)) {
              const element = subsidi[key];
              if (
                Object.hasOwnProperty.call(this.formDetail.subsidi, element.id)
              ) {
                this.formDetail.subsidi[element.id] = this.formatMoney(
                  element.nilai
                );
              }
            }
          }
        },
        () => {
          this.onGetData = false;
          showAlert(this.$swal, {
            title: "PERHATIAN!",
            msg: "Data payroll sebelumnya tidak tersedia",
            showConfirmButton: false,
          });
        }
      );
    },

    tooltip() {
      $('[data-toggle="tooltip"]').tooltip();
    },

    postData() {
      this.isSubmit = true;
      var data = { ...this.formData };
      data.bulan = moment(
        data.bulan.year + "-" + (data.bulan.month + 1) + "-01"
      ).format("YYYY-MM");
      data.detail = this.listKaryawan.filter((e) => this.selectedEmployee.indexOf(e.id_karyawan) >= 0);
      for (const key in data.detail) {
        if (Object.hasOwnProperty.call(data.detail, key)) {
          const element = data.detail[key]
          data.detail[key].potongan[this.formData.item_pph] = element.nilai_pph;
          data.detail[key].nilai_potongan += element.nilai_pph;
        }
      }
      post_PayrollSave(
        data,
        (res) => {
          this.isSubmit = false;
          var msg =
            res.response_code == 201
              ? "Data Fee Man Power berhasil dicatat"
              : "Data Fee Man Power berhasil diperbarui";
          showAlert(this.$swal, {
            title: "BERHASIL!",
            msg: msg,
            onSubmit: () => {
              this.$router.push({
                name: "DataPayroll",
              });
            },
            onDismiss: () => {
              this.$router.push({
                name: "DataPayroll",
              });
            },
          });
        },
        () => {
          this.isSubmit = false;
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Terjadi kesalahan, silakan ulangi kembali",
            showConfirmButton: false,
            showCancelButton: true,
          });
        }
      );
    },
    async onSubmit() {
      for (const key in this.rules) {
        if (Object.hasOwnProperty.call(this.rules, key)) {
          this.rules[key].changed = true;
        }
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      if (check.success && this.selectedEmployee.length > 0) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData();
          },
        });
      } else {
        if (this.selectedEmployee.length == 0) {
          showAlert(this.$swal, {
            title: "PERHATIAN!",
            msg: "Silakan pilih karyawan terlebih dahulu",
            showConfirmButton: false,
          });
        } else if (this.listKaryawan.length === 0) {
          showAlert(this.$swal, {
            title: "PERHATIAN!",
            msg: "Ambil data karyawan terlebih dahulu sebelum menyimpan data",
            showConfirmButton: false,
          });
        }
      }
    },
  },
};
</script>

<style scoped src="../../../../assets/css/style.css"></style>

<style scoped src="../../../../assets/css/custom.css"></style>

<style scoped>
.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.modal .title {
  font-size: 16px;
}

.modal .btn-add {
  font-size: 12px;
}

.info-detail .title {
  font-size: 18px;
  font-weight: 500;
}

.info-detail .sub-title {
  color: #c6c6c6;
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
}

.modal-dialog {
  max-width: 800px;
}

.modal-content {
  height: 100%;
  border-radius: 0px;
}

.modal-content {
  overflow-y: auto;
}

.modal-footer {
  position: fixed;
  bottom: 0;
  background-color: #fff;
  width: 100%;
  padding-right: 40px;
}

.modal-body {
  padding-bottom: 60px;
}
</style>
